import { html } from '../../../utils/lit/html.js';
import { CSS_PARTS } from '../css-parts.js';

const MIN_ALLOWED_BOOKMAKERS_NUMBER = 3;
const MIN_ALLOWED_NON_EXCHANGE_BOOKMAKERS_NUMBER = 2;

const oddscheckerFallbackImageUK = new URL(
  '../assets/images/oddschecker-fallback-image-uk.jpg',
  import.meta.url
);
const oddscheckerFallbackImageItaly = new URL(
  '../assets/images/oddschecker-fallback-image-italy.png',
  import.meta.url
);

const hrefUK = 'https://www.oddschecker.com';
const hrefUS = 'https://www.oddschecker.com/us';
const hrefItaly = 'https://www.oddschecker.com/it';

function getFallbackViewHref(theme) {
  if (theme === 'reach') {
    return hrefUK;
  } else if (theme === 'golf-monthly' || theme === 'oddschecker') {
    return hrefUS;
  }

  return hrefItaly;
}

function getFallbackViewImage(theme) {
  if (
    theme === 'reach' ||
    theme === 'golf-monthly' ||
    theme === 'oddschecker'
  ) {
    return oddscheckerFallbackImageUK;
  }

  return oddscheckerFallbackImageItaly;
}

export const getFallbackView = (theme, fallbackViewText) => {
  return html`
    <div part="${CSS_PARTS['fallback-view']}" class="fallback-view">
      <a
        href="${getFallbackViewHref(theme)}"
        target="_blank"
        rel="noopener"
        class="fallback-view__link"
      >
        <main
          part="${CSS_PARTS['fallback-view__body']}"
          class="fallback-view__body"
        >
          <div
            part="${CSS_PARTS['fallback-view__text']}"
            class="fallback-view__text"
          >
            ${fallbackViewText}
          </div>

          <img
            class="fallback-view__image"
            src="${getFallbackViewImage(theme)}"
            alt="Oddschecker bookmakers"
          />
        </main>
      </a>
    </div>
  `;
};

export const shouldDisplayFallback = (
  bookmakersCount,
  hasOnlyExchangeBookmakers
) => {
  return (
    bookmakersCount < MIN_ALLOWED_NON_EXCHANGE_BOOKMAKERS_NUMBER ||
    (!hasOnlyExchangeBookmakers &&
      bookmakersCount < MIN_ALLOWED_BOOKMAKERS_NUMBER)
  );
};
