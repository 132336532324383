export const getParticipants = subevent => {
  const firstParticipant =
    subevent && subevent.participants && subevent.participants.homeTeam
      ? subevent.participants.homeTeam.name
      : '';
  const secondParticipant =
    subevent && subevent.participants && subevent.participants.awayTeam
      ? subevent.participants.awayTeam.name
      : '';

  return {
    firstParticipant,
    secondParticipant
  };
};
