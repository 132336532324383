export const orderBets = market => {
  const { bets } = market;
  const orderedBets = Array.from(bets);
  const finalOrderedBets = [];

  if (market.marketType !== 1) {
    return bets;
  }

  // NOTE: There is no need for optional chaining or any other chack because if
  // the marketGenericNameSequence is not valid the initial bets will be returned
  try {
    const marketSequense = orderedBets[0].marketGenericNameSequence
      .split('|')
      .map(s => s.trim().toUpperCase());

    if (marketSequense.length < 2) {
      return bets;
    }

    orderedBets.forEach(b => {
      if (b.genericName.toLowerCase().trim() === 'draw') {
        b.betName = 'X';
      }
      finalOrderedBets[
        marketSequense.indexOf(b.genericName.trim().toUpperCase())
      ] = b;
    });

    return finalOrderedBets;
  } catch (error) {
    return bets;
  }
};
