import {
  createCSSPartsObjFromList,
  createCSSPartsProxy
} from '../utils/cssParts/cssParts';

export const CSS_PARTS_LIST = ['footer', 'footer__notice', 'footer__icons'];

export const CSS_PARTS = createCSSPartsProxy(
  createCSSPartsObjFromList(CSS_PARTS_LIST)
);
