import { html } from '../../utils/lit/html.js';
import { CSS_PARTS } from '../css-parts.js';

const over18IconBlack = new URL(
  '../assets/icons/over-18-icon-black.svg',
  import.meta.url
);
const aamsIconBlack = new URL(
  '../assets/icons/aams-icon-black.svg',
  import.meta.url
);
const amdIconBlack = new URL(
  '../assets/icons/amd-icon-black.svg',
  import.meta.url
);
const giocoResponsabileIconBlack = new URL(
  '../assets/icons/gioco-responsabile-icon-black.svg',
  import.meta.url
);

const over18IconWhite = new URL(
  '../assets/icons/over-18-icon-white.svg',
  import.meta.url
);
const aamsIconWhite = new URL(
  '../assets/icons/aams-icon-white.svg',
  import.meta.url
);
const amdIconWhite = new URL(
  '../assets/icons/amd-icon-white.svg',
  import.meta.url
);
const giocoResponsabileIconWhite = new URL(
  '../assets/icons/gioco-responsabile-icon-white.svg',
  import.meta.url
);

const beGambleAwareIconBlack = new URL(
  '../assets/icons/be-gamble-aware-icon-black.svg',
  import.meta.url
);

const oddscheckerIconUS = new URL(
  '../assets/icons/oddschecker-logo-us-dark.png',
  import.meta.url
);

function getFooterNotice(theme) {
  if (theme === 'reach' || theme === 'oddschecker') {
    return html``;
  } else if (theme === 'golf-monthly') {
    return html`21+ Gambling problem? Call 1-800-GAMBLER`;
  }

  return html`
    Il gioco può causare dipendenza patologica ed è vietato ai minori.
    Percentuali di vincita su ADM e sui siti degli operatori. Cliccando su una
    quota si è diretti su oddschecker, da cui è possibile accedere al sito
    dell'operatore per verificarne i principi di cui all'art. 5.6 delle linee
    guida della Legge 9 agosto 2018, n.96
  `;
}

function getFooterLegalIcons(theme) {
  if (theme === 'reach') {
    return html`
      <img src="${beGambleAwareIconBlack}" alt="Over 18 Be Gamble Aware" />
    `;
  } else if (theme === 'golf-monthly' || theme === 'oddschecker') {
    return html``;
  } else if (theme === 'daznbet') {
    return html`
      <img src="${over18IconWhite}" alt="Over 18" />
      <img src="${aamsIconWhite}" alt="AAMS" />
      <img src="${amdIconWhite}" alt="ADM" />
      <img src="${giocoResponsabileIconWhite}" alt="GIOCO RESPONSABILE" />
    `;
  }

  return html`
    <img src="${over18IconBlack}" alt="Over 18" />
    <img src="${aamsIconBlack}" alt="AAMS" />
    <img src="${amdIconBlack}" alt="ADM" />
    <img src="${giocoResponsabileIconBlack}" alt="GIOCO RESPONSABILE" />
  `;
}

function getFooterPoweredBy(theme) {
  if (theme === 'oddschecker') {
    return html``;
  }

  return html`Powered by
    <img src="${oddscheckerIconUS}" alt="Oddschecker Logo" />`;
}

export const getFooter = theme => {
  const footerNotice = getFooterNotice(theme);
  const footerLegalIcons = getFooterLegalIcons(theme);
  const footerPoweredBy = getFooterPoweredBy(theme);

  return html`
    <footer part="${CSS_PARTS['footer']}" class="footer">
      <div part="${CSS_PARTS['footer__notice']}" class="footer__notice">
        <slot name="footer-notice"> ${footerNotice} </slot>
      </div>

      <div part="${CSS_PARTS['footer__icons']}" class="footer__icons">
        <div>
          <slot name="footer-legal-icons"> ${footerLegalIcons} </slot>
        </div>

        <div>${footerPoweredBy}</div>
      </div>
    </footer>
  `;
};
