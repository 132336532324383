import {
  createCSSPartsObjFromList,
  createCSSPartsProxy
} from '../../utils/cssParts/cssParts';

export const CSS_PARTS_LIST = [
  'base',
  'base__content',
  'footer',
  'footer__notice',
  'footer__icons',
  'header',
  'container',
  'table-container',
  'table-container__header-cell',
  'table-container__bookie-cell',
  'table-container__odd-cell',
  'table-container__odd-cell-cta',
  'table-container__odd-cell-cta--highlighted',
  'fallback-view',
  'fallback-view__body',
  'fallback-view__text'
];

export const CSS_PARTS = createCSSPartsProxy(
  createCSSPartsObjFromList(CSS_PARTS_LIST)
);
