const ODDS_FORMATS = {
  AMERICAN: 'american',
  DECIMAL: 'decimal',
  FRACTIONAL: 'fractional'
};

export const getFormattedOdds = (
  bet,
  oddsFormat,
  bookie,
  placeholder = '---'
) => {
  const oddsOr = odds => odds || placeholder;
  const bookiePrice = bet.odds.find(price => price.bookieCode === bookie);

  if (!bookiePrice) {
    return placeholder;
  }

  switch (oddsFormat) {
    case ODDS_FORMATS.FRACTIONAL:
      return oddsOr(bookiePrice.oddsFractional);
    case ODDS_FORMATS.DECIMAL:
      return oddsOr(bookiePrice.oddsDecimal);
    case ODDS_FORMATS.AMERICAN:
      return oddsOr(bookiePrice.oddsUs);
    default:
      return placeholder;
  }
};
