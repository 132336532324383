import { upgradeProperty } from '../../utils/upgradeProperty/upgradeProperty.js';
import { html } from '../../utils/lit/html.js';
import { classnames } from '../../utils/classnames/classnames.js';
import { mediaQuery } from '../../utils/mediaQuery/mediaQuery.js';
import { isPlainObject } from '../../utils/isPlainObject/isPlainObject.js';
import { pickBy } from '../../utils/pickBy/pickBy.js';
import { Analytics } from '../../services/analytics.js';
import { getFormattedOdds } from './helpers/getFormattedOdds.js';
import { orderBets } from './helpers/orderBets.js';
import { getHeader } from './header/header.js';
import { getFooter } from '../../shared/footer/footer.js';
import { getFallbackView, shouldDisplayFallback } from './fallback/fallback.js';
import { http } from './services/http.js';
import { styles } from './styles/styles.js';
import { CSS_PARTS } from './css-parts.js';

const WIDGET_VERSION = '4.7.0'; // NOTE: This value should match the last version from the widget's CHANGELOG.md file.
const WIDGET_NAME = 'oc-odds-comparison-widget';

let clickoutServiceUrl = '';

try {
  clickoutServiceUrl = process.env.CLICKOUT_SERVICE_URL;
} catch {
  // Test environment fails to resolve `process`, therefore we provide a safeguard.
  clickoutServiceUrl = '';
}

const sizeClasses = {
  large: 'base--large',
  small: 'base--small'
};

/**
 * Checks validity of data retrieved from context-engine.
 *
 * NOTE: We need to check that the widget type is the expected one, becase context-engine
 * might bring data for a different widget when using the same `slotId`.
 *
 * @param {Object} data The data retrieved from context-engine.
 * @returns {Boolean} Returns `true` if data are valid, otherwise `false`.
 */
const isValidData = data => {
  return isPlainObject(data) && data.journey === 'comparison-responsive-table';
};

const template = document.createElement('template');

template.innerHTML = /* html */ `
  <style>
    ${styles}
  </style>

  <div part="${CSS_PARTS['base']}" class="base" id="rootElement">
    <slot name="loading" hidden></slot>
    <slot name="error" hidden></slot>

    <div part="${CSS_PARTS['base__content']}" class="base__content" id="content"></div>
  </div>
`;

class OCOddsComparisonWidget extends HTMLElement {
  constructor() {
    super();

    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    this._version = WIDGET_VERSION;
  }

  #resizeObserver;
  #initialData = null;
  #unsubscribeScrollableMediaQuery;

  #state = {
    data: null,
    analytics: false
  };

  static get observedAttributes() {
    return ['scrollable-media-query'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'scrollable-media-query' && oldValue !== newValue) {
      this.#unsubscribeFromScrollableMediaQueryChanges();
      this.#subscribeToScrollableMediaQueryChanges();

      if (newValue == null || newValue === '') {
        // When removing the attribute, imply that media query does not match.
        this.#onScrollableMediaQueryChange(false);
      }
    }
  }

  get slotId() {
    return this.getAttribute('slot-id');
  }

  set slotId(value) {
    this.setAttribute('slot-id', value);
  }

  get overrideUrl() {
    return this.getAttribute('override-url');
  }

  set overrideUrl(value) {
    this.setAttribute('override-url', value);
  }

  get sport() {
    return this.getAttribute('sport');
  }

  set sport(value) {
    this.setAttribute('sport', value);
  }

  get participants() {
    return this.getAttribute('participants');
  }

  set participants(value) {
    this.setAttribute('participants', value);
  }

  get participant() {
    return this.getAttribute('participant');
  }

  set participant(value) {
    this.setAttribute('participant', value);
  }

  get competition() {
    return this.getAttribute('competition');
  }

  set competition(value) {
    this.setAttribute('competition', value);
  }

  get theme() {
    return this.getAttribute('theme');
  }

  set theme(value) {
    this.setAttribute('theme', value);
  }

  get inheritFontFamily() {
    return this.hasAttribute('inherit-font-family');
  }

  set inheritFontFamily(value) {
    if (value) {
      this.setAttribute('inherit-font-family', '');
    } else {
      this.removeAttribute('inherit-font-family');
    }
  }

  get disableAnalytics() {
    return this.hasAttribute('disable-analytics');
  }

  set disableAnalytics(value) {
    if (value) {
      this.setAttribute('disable-analytics', '');
    } else {
      this.removeAttribute('disable-analytics');
    }
  }

  get scrollableMediaQuery() {
    return this.getAttribute('scrollable-media-query');
  }

  set scrollableMediaQuery(value) {
    this.setAttribute('scrollable-media-query', value);
  }

  get _initialData() {
    return this.#initialData;
  }

  // NOTE `_initialData` should not be used manually.
  // Its purpose is to be used by `oc-connect-widget` to provide the initial data to the child component.
  set _initialData(value) {
    if (isPlainObject(value)) {
      this.#initialData = value;
    }
  }

  async connectedCallback() {
    upgradeProperty(this, 'slotId');
    upgradeProperty(this, 'overrideUrl');
    upgradeProperty(this, 'sport');
    upgradeProperty(this, 'participants');
    upgradeProperty(this, 'participant');
    upgradeProperty(this, 'competition');
    upgradeProperty(this, 'theme');
    upgradeProperty(this, 'inheritFontFamily');
    upgradeProperty(this, 'disableAnalytics');
    upgradeProperty(this, 'scrollableMediaQuery');
    upgradeProperty(this, '_initialData');

    const loadingSlot = this.shadowRoot.querySelector('slot[name="loading"]');
    const rootElement = this.shadowRoot.getElementById('rootElement');

    loadingSlot.hidden = false;

    rootElement.addEventListener('click', this.#handleActions);

    const data = await this.#fetchData();

    if (!isValidData(data)) {
      return;
    }

    this.theme = this.theme || data.widgetStyle || 'default';

    this.#render(data, this.theme);

    if (!this.disableAnalytics) {
      if (Math.random() < (data.trackingRate || 0)) {
        this.#state.analytics = true;
        this.analytics = new Analytics(data.gtmId);
        this.analytics.integrateAnalytics();
      }
    }

    if ('IntersectionObserver' in window) {
      const intersectionObserver = new IntersectionObserver(entries => {
        const entry = entries[0];

        if (entry.isIntersecting) {
          this.#trackEvent({
            campaignName: data.campaignName,
            event: 'impression',
            targetUrl: data.targetUrl,
            widgetName: data.journey,
            client: window.location.host,
            page: window.location.href
          });

          intersectionObserver.disconnect();
        }
      });

      intersectionObserver.observe(this);
    }

    if ('ResizeObserver' in window) {
      // FUTURE: When CSS Container Queriues are well supported, we can use them instead of ResizeObserver.
      // https://caniuse.com/?search=CSS%20Container%20Queries
      this.#resizeObserver = new ResizeObserver(entries => {
        const entry =
          Array.isArray(entries) && entries.length > 0 ? entries[0] : undefined;

        if (entry) {
          const inlineBorderBoxSize = entry?.borderBoxSize?.[0]?.inlineSize;
          const clientWidth = entry?.target?.clientWidth;
          const containerWidth = inlineBorderBoxSize || clientWidth;

          if (containerWidth) {
            const rootElement = this.shadowRoot.getElementById('rootElement');

            if (containerWidth < 500) {
              rootElement.classList.remove(sizeClasses.large);
              rootElement.classList.add(sizeClasses.small);
            } else {
              rootElement.classList.remove(sizeClasses.small);
              rootElement.classList.add(sizeClasses.large);
            }
          }
        }
      });
    }

    this.#unsubscribeFromScrollableMediaQueryChanges();
    this.#subscribeToScrollableMediaQueryChanges();
    this.#startResizeObserver();
    this.#applyGazzettaLayoutFix();
  }

  disconnectedCallback() {
    const rootElement = this.shadowRoot.getElementById('rootElement');

    rootElement.removeEventListener('click', this.#handleActions);

    this.#unsubscribeFromScrollableMediaQueryChanges();
    this.#stopResizeObserver();
  }

  /**
   * NOTE: Ugly hack specific to Gazzetta's integration of the widget.
   * It can be safely removed in the future only if Gazzetta decides to fix it on their side.
   *
   * This is HTML tree that Gazzetta sometimes renderes the widget:
   * <div class="adv">
   *   <div>
   *     <div class="ocw"><!-- Widget's code goes here --></div>
   *   </div>
   * </div>
   *
   * The element with `adv` class name is a flexbox container, but the immediate `<div>` child
   * has no constraints, eg width or min-width, therefore the widget's table (which does not wrap by default)
   * exceeds the desired width of the container.
   *
   * The solution provided is specific for Gazzetta and we just apply a constraint
   * to the affected container so that the widget does not overflow.
   */
  #applyGazzettaLayoutFix() {
    const isGazzettaWebsite = window.location.host.includes('gazzetta.it');
    const gazzettaAdvContainer = this.closest('.adv > div');

    if (isGazzettaWebsite && gazzettaAdvContainer) {
      gazzettaAdvContainer.style.minWidth = 0;
    }
  }

  #trackEvent(event) {
    if (!this.#state.analytics) {
      return;
    }

    this.analytics.trackEvent(event);
  }

  #startResizeObserver() {
    if (!this.#resizeObserver) {
      return;
    }

    const contentElement = this.shadowRoot.getElementById('content');

    this.#resizeObserver.unobserve(contentElement);
    this.#resizeObserver.observe(contentElement);
  }

  #stopResizeObserver() {
    this.#resizeObserver && this.#resizeObserver.disconnect();
  }

  #subscribeToScrollableMediaQueryChanges() {
    this.#unsubscribeScrollableMediaQuery = mediaQuery(
      this.scrollableMediaQuery,
      this.#onScrollableMediaQueryChange
    );
  }

  #unsubscribeFromScrollableMediaQueryChanges() {
    if (typeof this.#unsubscribeScrollableMediaQuery === 'function') {
      this.#unsubscribeScrollableMediaQuery();
    }
  }

  #onScrollableMediaQueryChange = matches => {
    const baseContentEl = this.shadowRoot.querySelector('.base__content');

    if (baseContentEl) {
      baseContentEl.classList.toggle(
        'base__content--vertically-scrollable',
        matches
      );
    }
  };

  #handleBetClickout(betId, bookieCode) {
    const affIds = this.#state.data.affIds;
    const apiKey = this.#state.data.apiKey;
    const clickoutTemplate = this.#state.data.clickoutTemplate;
    const clickoutRedirectTime = this.#state.data.clickoutRedirectTime;
    const oddsClickoutCustomURL = this.#state.data.oddsClickoutCustomURL;
    const market = this.#state.data.subevents[0].markets[0];
    const selectedBet = market.bets.find(
      bet => String(bet.betId) === String(betId)
    );
    const uppercaseBookieCode = bookieCode.toUpperCase();
    const odd = selectedBet.odds.find(
      o => o.bookieCode === uppercaseBookieCode
    );

    this.#trackEvent({
      event: 'widgetClickout',
      widgetName: this.#state.data.journey,
      campaignName: this.#state.data.campaignName,
      bookieCode,
      targetUrl: this.#state.data.targetUrl,
      client: window.location.host,
      page: window.location.href,
      betName: selectedBet.betName,
      categoryGroupName: selectedBet.categoryGroupId,
      eventName: selectedBet.eventName,
      marketName: selectedBet.marketName,
      odds: odd.oddsFractional,
      oddsDecimal: odd.oddsDecimal,
      subeventName: selectedBet.subeventName
    });

    let clickoutUrl = '';

    if (oddsClickoutCustomURL) {
      clickoutUrl = oddsClickoutCustomURL;
    } else {
      const affId =
        affIds && affIds[uppercaseBookieCode]
          ? affIds[uppercaseBookieCode]
          : '';
      const pathParams = new URLSearchParams();

      pathParams.append('bookieCode', uppercaseBookieCode);
      pathParams.append('betIds', betId);
      pathParams.append('apiKey', apiKey);
      pathParams.append('affId', affId);
      pathParams.append('template', clickoutTemplate);
      pathParams.append('redirectInterval', clickoutRedirectTime);
      pathParams.append('analytics', 'on'); // Always track in clickout-service per request.

      clickoutUrl = `${clickoutServiceUrl}?${pathParams.toString()}`;
    }

    window.open(clickoutUrl, '_blank', 'noopener noreferrer');

    this.dispatchEvent(
      new CustomEvent(`${WIDGET_NAME}:bet-clickout`, {
        bubbles: true,
        composed: true,
        detail: { clickoutUrl }
      })
    );
  }

  #handleActions = evt => {
    const betButton = evt.target.closest('.table-container__odd-cell-cta');
    const fallbackLink = evt.target.closest('.fallback-view__link');

    if (betButton) {
      const { betId, bookieCode } = betButton.dataset;

      this.#handleBetClickout(betId, bookieCode);
    }

    if (fallbackLink) {
      this.#trackEvent({
        event: 'fallback_redirect',
        widgetName: this.#state.data.journey,
        campaignName: this.#state.data.campaignName,
        client: window.location.host,
        page: window.location.href
      });
    }
  };

  async #fetchData() {
    try {
      const contextualConfig = pickBy(
        {
          sport: this.sport,
          participants: this.participants,
          participant: this.participant,
          competition: this.competition
        },
        key => key
      );

      const data =
        this._initialData ||
        (await http.fetchCampaignData(
          this.slotId,
          this.overrideUrl,
          Object.keys(contextualConfig).length !== 0 ? contextualConfig : void 0
        ));

      this.#state.data = { ...data };

      this.dispatchEvent(
        new CustomEvent(`${WIDGET_NAME}:load-success`, {
          bubbles: true,
          composed: true,
          detail: { data }
        })
      );

      return data;
    } catch (error) {
      const errorSlot = this.shadowRoot.querySelector('slot[name="error"]');

      errorSlot.hidden = false;

      this.dispatchEvent(
        new CustomEvent(`${WIDGET_NAME}:load-error`, {
          bubbles: true,
          composed: true,
          detail: { error }
        })
      );
    } finally {
      const loadingSlot = this.shadowRoot.querySelector('slot[name="loading"]');

      loadingSlot.hidden = true;

      this.dispatchEvent(
        new Event(`${WIDGET_NAME}:load`, {
          bubbles: true,
          composed: true
        })
      );
    }
  }

  #renderImpressionImages(bookies) {
    // NOTE: Pokerstars (IP) has required to track the impressions of their odds. We track the bookmaker impression in both our side and theirs.
    // NOTE: Numbers in our side and numbers in their side may differ due to the fact that we are using about different tracking tools.

    if (bookies.find(bookieCode => bookieCode.toUpperCase() === 'IP')) {
      const impressionImageIsAlreadyInDOM = this.shadowRoot.querySelector(
        'img[data-impression-bookmaker-code="IP"]'
      );

      if (!impressionImageIsAlreadyInDOM) {
        const impressionImageElement = document.createElement('img');
        impressionImageElement.src = `https://ad.doubleclick.net/ddm/trackimp/N470006.4378641TSG-DIRECT-ODDSCH/B27131974.326150330;dc_trk_aid=518347485;dc_trk_cid=164863477;ord=${new Date().getTime()};dc_lat=0;tag_for_child_directed_treatment=0;tfua=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ltd=?`;
        impressionImageElement.setAttribute(
          'data-impression-bookmaker-code',
          'IP'
        );
        impressionImageElement.classList.add('impression-image');

        this.shadowRoot
          .getElementById('rootElement')
          .append(impressionImageElement);

        this.#trackEvent({
          event: 'bookieImpression',
          bookieCode: 'IP',
          widgetName: this.#state.data.journey,
          campaignName: this.#state.data.campaignName,
          targetUrl: this.#state.data.targetUrl,
          client: window.location.host,
          page: window.location.href
        });
      }
    }
  }

  #renderBookieRow(bookieCode, bookieIndex, oddsFormat, bets, bookieCodes) {
    const bookmaker = this.#state.data?.bookies?.find(bookie => {
      return bookie.code?.toLowerCase() === bookieCode?.toLowerCase();
    });

    return html` <tr>
      ${[{}, ...bets].map((bet, betIndex) => {
        if (bookieIndex === 0 && betIndex === 0) {
          return html`<th
            part="${CSS_PARTS['table-container__header-cell']}"
            class="table-container__header-cell"
          ></th>`;
        }

        if (bookieIndex === 0 && betIndex > 0) {
          return html`<th
            part="${CSS_PARTS['table-container__header-cell']}"
            class="table-container__header-cell"
          >
            ${bet.betName}
          </th>`;
        }

        if (bookieIndex > 0 && betIndex === 0) {
          return html`
            <td
              part="${CSS_PARTS['table-container__bookie-cell']}"
              class="table-container__bookie-cell"
            >
              <img
                src="${bookmaker?.logoFull}"
                alt="${bookmaker?.displayName || bookieCode}"
                loading="lazy"
                data-bookmaker-code="${bookieCode}"
                style="background-color: ${bookmaker?.primaryColor ||
                '#ffffff'};"
              />
            </td>
          `;
        }

        return html`
          <td
            part="${CSS_PARTS['table-container__odd-cell']}"
            class="table-container__odd-cell"
          >
            ${(() => {
              const odd = bet.odds.find(
                o => o.bookieCode.toLowerCase() === bookieCode
              );

              const maxOdd = bookieCodes.reduce(
                (maxOddAcc, bookieCode) => {
                  const currentOdd = bet.odds.find(
                    o => o.bookieCode.toLowerCase() === bookieCode
                  );

                  const enhancedOdd =
                    currentOdd &&
                    currentOdd.oddsDecimal &&
                    Number(currentOdd.oddsDecimal)
                      ? {
                          decimalPrice: Number(currentOdd.oddsDecimal),
                          formattedPrice: getFormattedOdds(
                            bet,
                            oddsFormat,
                            currentOdd.bookieCode
                          )
                        }
                      : {
                          formattedPrice: '0',
                          decimalPrice: 0
                        };

                  return enhancedOdd.decimalPrice > maxOddAcc.decimalPrice
                    ? enhancedOdd
                    : maxOddAcc;
                },
                {
                  formattedPrice: '-',
                  decimalPrice: 0
                }
              );

              const price = odd
                ? getFormattedOdds(bet, oddsFormat, odd.bookieCode)
                : '...';
              const isHighLighted = price === maxOdd.formattedPrice;
              const disabled = price === '...' ? 'disabled' : '';

              return html`
                <button
                  ${disabled}
                  part="${classnames(
                    CSS_PARTS['table-container__odd-cell-cta'],
                    {
                      [CSS_PARTS['table-container__odd-cell-cta--highlighted']]:
                        isHighLighted
                    }
                  )}"
                  class="${classnames('table-container__odd-cell-cta', {
                    ['table-container__odd-cell-cta--highlighted']:
                      isHighLighted
                  })}"
                  data-bookie-code="${bookieCode}"
                  data-bet-id="${bet.betId}"
                >
                  ${price}
                </button>
              `;
            })()}
          </td>
        `;
      })}
    </tr>`;
  }

  #render(data, theme) {
    const content = this.shadowRoot.getElementById('content');

    const footer = getFooter(theme);

    const allBookmakers = data.bookies;
    const oddsFormat = data.oddsFormat;
    const subevent = data?.subevents?.[0];

    const bookmakers = !subevent
      ? []
      : Array.from(
          new Set(
            subevent.markets[0].bets.reduce(
              (acc, bet) =>
                bet.odds.reduce(
                  (bookieCodes, odd) =>
                    bookieCodes.concat(odd.bookieCode.toLowerCase()),
                  acc
                ),
              []
            )
          )
        );

    const numOfExchangeBookmakers = allBookmakers.filter(b => {
      return (
        bookmakers.includes(b.code.toLowerCase()) &&
        (b.bookmakerType || '').toLowerCase() === 'exchange'
      );
    }).length;

    const hasOnlyExchangeBookmakers =
      numOfExchangeBookmakers === bookmakers.length && bookmakers.length > 0;

    if (hasOnlyExchangeBookmakers) {
      content.classList.add('base__content--exchange');
    }

    if (shouldDisplayFallback(bookmakers.length, hasOnlyExchangeBookmakers)) {
      const fallbackViewText = data?.fallbackViewText || '';
      const fallbackView = getFallbackView(theme, fallbackViewText);

      content.innerHTML = html`
        ${fallbackView}

        <div style="padding-bottom: 8px;">${footer}</div>
      `;
      return;
    }

    this.#renderImpressionImages(bookmakers);

    const market = subevent.markets[0];
    const bets = orderBets(market);
    const header = getHeader(subevent);

    content.innerHTML = html`
      <div part="${CSS_PARTS['container']}" class="container">
        <header part="${CSS_PARTS['header']}" class="header">${header}</header>

        <div part="${CSS_PARTS['table-container']}" class="table-container">
          <table>
            ${['', ...(bookmakers || [])].map(
              (bookie, bookieIndex) =>
                html`${this.#renderBookieRow(
                  bookie,
                  bookieIndex,
                  oddsFormat,
                  bets,
                  bookmakers
                )}`
            )}
          </table>
        </div>
        ${footer}
      </div>
    `;
  }
}

if (window.customElements && !window.customElements.get(WIDGET_NAME)) {
  window.customElements.define(WIDGET_NAME, OCOddsComparisonWidget);
}
