import { isPlainObject } from '../../../utils/isPlainObject/isPlainObject.js';

let endpoint = '';

try {
  endpoint = process.env.CONTEXT_ENGINE_CAMPAIGNS_ENDPOINT;
} catch {
  // Test environment fails to resolve `process`, therefore we provide a safeguard.
  endpoint = '';
}

/**
 * Fetches offers that are contained inside a variation container from CMS.
 *
 * @param {String} slotId The selected slot of the campaign.
 * @param {String} overrideUrl The url that overrides the actual referer.
 * @param {Object} [contextualConfig={sport, competition, participants, participant}] The configuration that is needed for contextual campaigns.
 * @returns {Promise} Returns a promise that resolves to the campaign's data or rejects if request fails for any reason.
 */
const fetchCampaignData = async (slotId, overrideUrl, contextualConfig) => {
  const host = {
    referer: overrideUrl || window.location.href,
    slot: slotId || ''
  };
  const hostToBase64 = window.btoa(JSON.stringify(host));

  const req = await fetch(`${endpoint}?json&host=${hostToBase64}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: isPlainObject(contextualConfig)
      ? JSON.stringify({ context: { ...contextualConfig } })
      : ''
  });

  if (req.status === 204) {
    throw {
      name: 'Request error',
      status: req.status,
      statusText: req.statusText,
      requestText: 'No Campaign Found'
    };
  }

  if (!req.ok) {
    throw {
      name: 'Request error',
      status: req.status,
      statusText: req.statusText,
      requestText: await req.text()
    };
  }

  const data = await req.json();

  return data;
};

export const http = {
  fetchCampaignData
};
