import { html } from '../../../utils/lit/html.js';
import { getParticipants } from '../helpers/getParticipants.js';

function getParticipantsHtml(firstParticipant, secondParticipant) {
  return html`
    <span>${firstParticipant}</span>
    <span class="header__participants-seperator">vs</span>
    <span>${secondParticipant}</span>
  `;
}

export const getHeader = subevent => {
  const { firstParticipant, secondParticipant } = getParticipants(subevent);

  return firstParticipant && secondParticipant
    ? getParticipantsHtml(firstParticipant, secondParticipant)
    : subevent.subeventName;
};
