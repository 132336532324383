export const laGazzettaDeloSportTheme = `
  --body-bg-color: #ebccce;
  --header-bg-golor: #000000;
  --header-color: #f87731;
  --odd-cell-bg-color: #f1f2f2;
  --odd-cell-bg-color-highlighted: #e27e3a;
  --odd-cell-color-highlighted: #ffffff;
  --scrollbar-thumb-color: #7d7277;

  .fallback-view--clean + div > .footer {
    display: none;
  }
`;
