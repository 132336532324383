import { defaultTheme } from './themes/default.js';
import { exchangeOverrides } from './themes/exchangeOverrides.js';
import { laGazzettaDeloSportTheme } from './themes/laGazzettaDeloSport.js';
import { gazzanetTheme } from './themes/gazzanet.js';
import { whoscoredTheme } from './themes/whoscored.js';
import { daznbetTheme } from './themes/daznbet.js';
import { reachTheme } from './themes/reach.js';
import { golfMonthlyTheme } from './themes/golfMonthly.js';
import { oddscheckerTheme } from './themes/oddschecker.js';
import { tuttoMercatoTheme } from './themes/tuttoMercato.js';

const fallbackBackgroundImage = new URL(
  '../assets/images/fallback-background-image.png',
  import.meta.url
);

export const stylesVars = {};

export const styles = /* css */ `
  /* Generic styles */
  :host {
    ${defaultTheme}

    all: initial;
    display: block;
    width: 100%;
    font-family: inherit;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    box-sizing: border-box;
  }

  :host([theme='la-gazzetta-delo-sport']) {
    ${laGazzettaDeloSportTheme}
  }

  :host([theme='gazzanet']) {
    ${gazzanetTheme}
  }

  :host([theme='whoscored']) {
    ${whoscoredTheme}
  }

  :host([theme='daznbet']) {
    ${daznbetTheme}
  }

  :host([theme='reach']) {
    ${reachTheme}
  }

  :host([theme='golf-monthly']) {
    ${golfMonthlyTheme}
  }

  :host([theme='oddschecker']) {
    ${oddscheckerTheme}
  }

  :host([theme='tutto-mercato']) {
    ${tuttoMercatoTheme}
  }

  :host .base__content--exchange {
    ${exchangeOverrides}
  }

  :host([hidden]),
  [hidden] {
    display: none !important;
  }

  :host(:not([inherit-font-family])) {
    font-family: var(--font-family);
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  p, ul, ol {
    margin-bottom: 1rem;
  }

  button {
    border: none;
    box-shadow: none;
    background-color: transparent;
    margin: 0;
  }

  button:disabled {
    cursor: not-allowed;
  }

  button:not(:disabled) {
    cursor: pointer;
  }

  a,
  a:visited {
    color: var(--anchor-color);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .hidden {
    display: none !important;
  }

  .base {
    --root-font-size: var(--base-font-size);
    --table-border-spacing: 4px;
    --table-header-font-size: calc(0.75 * var(--base-font-size));
    --table-cell-y-padding: 2px;
    --table-cell-height: calc(1.875 * var(--base-font-size));
    --table-header-row-height: calc(1.625 * var(--base-font-size));
    --scrollable-area-height: calc(var(--table-header-row-height) + var(--table-cell-y-padding) + 9.5 * var(--table-cell-height)); /* NOTE: We need this calculation in order to display 9 and a half rows of the table when widget is vertically scrollable */
    --logo-margin-right: 4px;
    --logo-width: calc(6.125 * var(--base-font-size));
    --footer-icons-height: 16px;
    --fallback-background-image: url(${`"${fallbackBackgroundImage}"`});

    font-size: var(--root-font-size);
  }

  .base--small {
    --root-font-size: calc(var(--base-font-size) * 0.875);
    --footer-icons-height: 0.875em;
  }

  .base--large {
    --root-font-size: var(--base-font-size);
  }

  .base__content:empty {
    display: none;
  }

  .base__content {
    background-color: var(--body-bg-color);
  }

  .impression-image {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .fallback-view {
    background-position-y: 80%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: var(--fallback-background-image);
    padding: 14px;
  }

  .fallback-view--clean {
    background-image: unset;
    margin-bottom: -20px;
  }

  .fallback-view--clean + div > .footer {
    display: none;
  }


  .fallback-view > a {
    display: block;
    text-decoration: none;
    color: unset;
  }

  .fallback-view__body {
    padding: 0px 8px;
    text-align: center;
  }

  .fallback-view__text {
    padding: 0;
    margin-bottom: 1rem;
    font-size: 1em;
    font-weight: bold;
    line-height: 1;
    color: var(--fallback-text-color);
  }

  .fallback-view__text:empty {
    display: none;
  }

  .fallback-view__image {
    width: 80%;
    height: auto;
    max-width: 600px;
  }

  .container {
    padding: 14px;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
    padding: 8px;
    color: var(--header-color);
    background-color: var(--header-bg-golor);
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
  }

  .header__participants-seperator {
    font-size: 0.875em;
    font-weight: normal;
  }

  .table-container {
    width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-bg-color);
    margin-top: 8px;
  }

  .base__content--vertically-scrollable .table-container {
    max-height: var(--scrollable-area-height);
  }

  .table-container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: var(--scrollbar-bg-color);
  }

  .table-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--scrollbar-thumb-color);
  }

  .table-container::-webkit-scrollbar-corner {
    background-color: var(--scrollbar-bg-color);
  }

  .table-container > table {
    width: 100%;
    border-spacing: var(--table-border-spacing);
  }

  .table-container > table > td,
  .table-container > table > th {
    padding: var(--table-cell-y-padding) 2px;
    vertical-align: middle;
  }

  .table-container > tr:first-child {
    height: var(--table-header-row-height);
  }

  .table-container__header-cell {
    white-space: nowrap;
    position: sticky;
    top: 0;
    text-align: center;
    font-size: var(--table-header-font-size);
    background-color: var(--body-bg-color);
    color: var(--odd-header-cell-color);
    font-weight: normal;
  }

  .table-container__header-cell:first-child {
    left: 0;
    z-index: 3;
    text-align: left;
    padding-left: 0;
  }

  .table-container > tr > th,
  .table-container > tr > td:first-of-type {
    background-color: var(--body-bg-color);
  }

  .table-container__bookie-cell {
    position: sticky;
    left: var(--table-border-spacing);
    z-index: 2;
    vertical-align: bottom;
    width: var(--logo-width);
    height: var(--table-cell-height);
    background-color: var(--body-bg-color);
  }

  .table-container__bookie-cell::before {
    /* Workaround to avoid odds cells appearing behind logos cells when scrolling horizontally, by creating a pseudoelement with same background color as the main body. */
    content: '';
    position: absolute;
    top: 0;
    left: calc(-1 * var(--table-border-spacing));
    width: var(--table-border-spacing);
    height: 100%;
    background-color: var(--body-bg-color);
  }

  .table-container__bookie-cell > img {
    display: block;
    width: var(--logo-width);
    height: var(--table-cell-height);
    margin-right: var(--logo-margin-right);
    border-radius: 4px;
  }

  .table-container__bookie-cell > img[src=""] {
    display: none;
  }

  .table-container__odd-cell {
    height: var(--table-cell-height);
  }

  .table-container__odd-cell-cta {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0.875em;
    font-weight: bold;
    border: none;
    box-shadow: unset;
    cursor: pointer;
    background-image: unset;
    min-width: 60px;
    margin-right: 0;
    background-color: var(--odd-cell-bg-color);
    color: var(--odd-cell-color);
    border-radius: 4px;
  }

  .table-container__odd-cell-cta:focus-visible {
      outline: 2px solid var(--button-focus-outline);
    }

  .table-container__odd-cell-cta:disabled {
    cursor: not-allowed;
  }

  .table-container__odd-cell-cta:hover:not(:disabled) {
    -webkit-filter: brightness(95%);
    filter: brightness(95%);
  }

  .table-container__odd-cell-cta--highlighted {
    background-color: var(--odd-cell-bg-color-highlighted);
    color: var(--odd-cell-color-highlighted);
  }

  .footer {
    padding: 0 8px;
    margin-top: 12px;
    font-size: 0.85em;
    font-weight: normal;
  }

  .footer__notice {
    color: var(--footer-notice-color);
  }

  .footer__icons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 6px;
    color: var(--footer-icons-color);
  }

  .footer__icons > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
  }

  .footer__icons img {
    height: var(--footer-icons-height);
  }
`;
